<template>
  <card-info-component
    :title="$t('pages.planing-team.widgets.employee_sick')"
    :value="value"
    :isLoading="initializing"
  ></card-info-component>
</template>

<script>
import { CardInfoComponent } from "@/pages/components";

import { TYPE_DAY_PLAN_DAYSICK } from "@/pages/responsible/planing/data";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    CardInfoComponent,
  },
  data() {
    return {
      date: new Date(),
      raw: [],
    };
  },
  computed: {
    ...mapGetters("planning", ["widgetCurrentDayData", "initializing"]),
    value() {
      let filtered = this.widgetCurrentDayData.filter(
        (x) => x.data[0].dayType == TYPE_DAY_PLAN_DAYSICK
      );
      return filtered.length;
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions("planning", ["getPlanEmployeeTypeAll"]),

    init() {
      this.getPlanEmployeeTypeAll(this.date);
    },
  },
};
</script>
