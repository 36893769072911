export const SHOW_PLANNING_EDIT_HOUR_MODAL = "show-planning-edit-modal";
export const SHOW_BIOMETRIC_HOUR_MODAL = "show-biometric-hour-modal";
export const SHOW_REAL_HOUR_MODAL = "show-real-hour-modal";
export const SHOW_CONFIRM_HOUR_MODAL = "show-confirm-hour-modal";
export const SHOW_WORK_SHIFT_ADD_MODAL = "show-workshift-add-modal";
export const SHOW_ABSENCE_ADD_MODAL = "show-absence-add-modal";
export const SHOW_ABSENCE_REVISION_ADD_MODAL = "show-absence-revison-add-modal";
export const SHOW_DAY_DETAIL_MODAL = "show-day-detail-modal";
export const EMPLOYEE_ABSENCE_ADDED = "employee-absence-added";
export const EMPLOYEE_WORK_SHIFT_ADDED = "employee-work-shift-added";
export const SHOW_OVERTIME_ADD_MODAL = "show-overtime-add-modal";
export const SHOW_OVERTIME_EDIT_MODAL = "show-overtime-edit-modal";
export const EMPLOYEE_OVERTIME_ADDED = "employee-overtime-added";
export const EMPLOYEE_OVERTIME_UPDATED = "employee-overtime-updated";
export const EMPLOYEE_OVERTIME_DELETED = "employee-overtime-deleted";
