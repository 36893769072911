import {
  SHOW_BIOMETRIC_HOUR_MODAL,
  SHOW_CONFIRM_HOUR_MODAL,
  SHOW_REAL_HOUR_MODAL,
  SHOW_WORK_SHIFT_ADD_MODAL,
  SHOW_ABSENCE_ADD_MODAL,
  SHOW_ABSENCE_REVISION_ADD_MODAL,
} from "./events";

const TYPE_DAY_PLAN_ABSENCE_JUSTIFIED = 0;
const TYPE_DAY_PLAN_ABSENCE_UNJUSTIFIED = 1;
const TYPE_DAY_PLAN_ABSENCE_CONFIRM_WAITING = 2;
const TYPE_DAY_PLAN_VACATIONS_CONFIRM_WAITING = 3;
const TYPE_DAY_PLAN_VACATIONS_APPROVED = 4;
const TYPE_DAY_PLAN_VACATIONS_NEED_REUNION = 5;
const TYPE_DAY_PLAN_DAYOFF = 6;
const TYPE_DAY_PLAN_DAYSICK = 7;
const TYPE_DAY_PLAN_WORKING = 8;

export {
  SHOW_BIOMETRIC_HOUR_MODAL,
  SHOW_CONFIRM_HOUR_MODAL,
  SHOW_REAL_HOUR_MODAL,
  SHOW_WORK_SHIFT_ADD_MODAL,
  SHOW_ABSENCE_ADD_MODAL,
  SHOW_ABSENCE_REVISION_ADD_MODAL,
  TYPE_DAY_PLAN_ABSENCE_JUSTIFIED,
  TYPE_DAY_PLAN_ABSENCE_UNJUSTIFIED,
  TYPE_DAY_PLAN_ABSENCE_CONFIRM_WAITING,
  TYPE_DAY_PLAN_VACATIONS_CONFIRM_WAITING,
  TYPE_DAY_PLAN_VACATIONS_APPROVED,
  TYPE_DAY_PLAN_VACATIONS_NEED_REUNION,
  TYPE_DAY_PLAN_DAYOFF,
  TYPE_DAY_PLAN_DAYSICK,
  TYPE_DAY_PLAN_WORKING,
};
